import { Card, Divider, Flex, Space, Tabs, Row, Tooltip, Typography, Descriptions } from "antd";
import React, { useState } from "react";
import ResultCard from "../../../../../components/ResultCard";
import { LaserClassResults } from "../../../../../components/LaserClassResults";
import { LaserClassResultsV2 } from "../MPE_AEL_Results/LaserClassResultsV2";
import LaserClassVerdict from "../MPE_AEL_Results/LaserClassVerdict";
import { Eyewear } from "../../../../../components/TestAndLabelLinks";
import eye from "../../../../../assets/Icons/eye black.png"
import hand from "../../../../../assets/Icons/hand black.png"
import beam from "../../../../../assets/Icons/beam black.png"
import { LaserClassResultsDiffuseReflective } from "../MPE_AEL_Results/LaserClassResultsDiffuseReflective";
import { Infograph } from "../../../../../components/infographicsModal/Infograph";
import { EyewearResults } from "../../ResultComponents/EyewearResults";
const { Text, Title } = Typography


export function DRResultCW({ result, units, laserOperation, data }) {
    const { MPE, AE } = result
    const textCenter = { display: 'flex', justifyContent: 'center' }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [info, setInfo] = useState(null)
    let props = {result: result, data:data , laserOperation: laserOperation}
    const handleClick = (e) => {
        setIsModalOpen(true)
        setInfo(e)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
  }
    return (<Tabs items={[{
        key: 1,
        label: "MPE Details",
        children: (
            <>
                <Tabs centered items={[{
                    key: 1,
                    label: result.albedo.num + "% Albedo",
                    children:
                        <>
                            <Infograph
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                handleCancel={handleCancel}
                                info={info}
                            />
                            <Card style={{ marginBottom: "20px" }}>
                                <Divider><h4><img src={eye} width={50} style={{ marginRight: "5px" }} /><strong>EYE</strong></h4></Divider>
                                {/* Result for Custom Albedo */}
                                <Flex justify="center">
                                    <strong style={{ margin: '12px' }}>Result for {result.albedo.num}% Albedo</strong>
                                </Flex>
                                <Flex justify='center' style={{ margin: '10px 10px' }}>
                                    <Space size='middle'>
                                        <Flex >
                                            <Card>
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>MPE</strong></Flex>
                                                    <Flex justify="center">
                                                        {(() => {
                                                            if (units === "joules") {
                                                                return result.MPE.joulesPerSqmPretty.eng + `J/m²`
                                                            }
                                                            else {
                                                                return result.MPE.wattsPerSqmPretty.eng + `W/m²`
                                                            }
                                                        })()}
                                                    </Flex>
                                                </Space>
                                            </Card>
                                        </Flex>

                                        <Flex>
                                            <Card>
                                                <Space direction="vertical">
                                                    <Flex><strong>Accessible Emission</strong></Flex>
                                                    <Flex justify="center">
                                                        {(() => {
                                                            if (units === "joules") {
                                                                return result.AE.albedo_joulesPerSqmPretyy
                                                            }
                                                            else {
                                                                return result.AE.albedo_wattsPerSqmPretty
                                                            }
                                                        })()}
                                                    </Flex>
                                                </Space>
                                            </Card>
                                        </Flex>

                                        <Flex>
                                            <Card>
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>Eye Safety Ratio</strong></Flex>
                                                    <Flex justify="center"
                                                        style={(() => {
                                                            if (result.MPEExcess.albedo_wattsPerSqmPretty <= 0.5) {
                                                                return { color: 'green' }
                                                            }
                                                            else if (result.MPEExcess.albedo_wattsPerSqmPretty > 0.5 && result.MPEExcess.albedo_wattsPerSqmPretty <= 1) {
                                                                return { color: 'orange' }
                                                            } else {
                                                                return { color: 'red' }
                                                            }
                                                        })()}
                                                    ><strong>{result.MPEExcess.albedo_wattsPerSqmPretty}</strong></Flex>
                                                </Space>
                                            </Card>
                                        </Flex>
                                    </Space>
                                </Flex>
                                <Card style={{
                                    backgroundColor: (() => {
                                        if (result.MPEExcess.albedo_wattsPerSqm > 1) {
                                            return "#d82424"
                                        }
                                        else if (result.MPEExcess.albedo_wattsPerSqm <= 0.5) {
                                            return "#10c96d"
                                        }
                                        else if (result.MPEExcess.albedo_wattsPerSqm > 0.5 || result.MPEExcess.albedo_wattsPerSqm <= 1) {
                                            return "#ffa200"
                                        }
                                    })()
                                }}

                                >
                                    <Flex justify="center">
                                        <strong style={{ color: "#ffffff" }}>
                                            {(() => {
                                                if (result.MPEExcess.wattsPerSqm > 1) {
                                                    return "The configuration that has been entered is Dangerous for the eyes!"
                                                }
                                                else if (result.MPEExcess.wattsPerSqm <= 0.5) {
                                                    return "The configuration that has been entered is Safe for the eyes!"
                                                }
                                                else if (result.MPEExcess.wattsPerSqm > 0.5 || result.MPEExcess.wattsPerSqm <= 1) {
                                                    return "The configuration that has been entered is Marginally Safe for the eyes!"
                                                }
                                            })()}
                                        </strong>
                                    </Flex>
                                </Card>
                            </Card>
                            <Card style={{ marginBottom: "20px" }}>
                                <Divider><h4><img src={hand} width={50} style={{ marginRight: "5px" }} /><strong> SKIN </strong> </h4></Divider>
                                <Flex justify="center" gap={"middle"} style={{ margin: '10px 10px' }}>
                                    <Card>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>MPE</strong></Flex>
                                            <Flex justify="center">
                                                {(() => {
                                                    if (units === "joules") {
                                                        return result.MPEskin.joulesPerSqmPretty.eng + `J/m²`
                                                    }
                                                    else {
                                                        return result.MPEskin.wattsPerSqmPretty.eng + `W/m²`
                                                    }
                                                })()}
                                            </Flex>
                                        </Space>
                                    </Card>
                                    <Card>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>Skin AE</strong></Flex>
                                            <Flex justify="center">
                                                {(() => {
                                                    if (units === "joules") {
                                                        return result.skinAE.albedo_joulesPerSqmPretyy
                                                    }
                                                    else {
                                                        return result.skinAE.albedo_wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Flex>
                                        </Space>
                                    </Card>
                                    <Card>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>Skin Safety Ratio</strong></Flex>
                                            <Flex justify="center"
                                                style={(() => {
                                                    if (result.skinMPEExcess.albedo_wattsPerSqmPretty <= 0.5) {
                                                        return { color: 'green' }
                                                    }
                                                    else if (result.skinMPEExcess.albedo_wattsPerSqmPretty > 0.5 && result.skinMPEExcess.albedo_wattsPerSqmPretty <= 1) {
                                                        return { color: 'orange' }
                                                    } else {
                                                        return { color: 'red' }
                                                    }
                                                })()}
                                            ><strong>{result.skinMPEExcess.albedo_wattsPerSqmPretty}</strong></Flex>
                                        </Space>
                                    </Card>
                                </Flex>
                                <Card style={{
                                    backgroundColor: (() => {
                                        if (result.skinMPEExcess.albedo_wattsPerSqm > 1) {
                                            return "#d82424"
                                        }
                                        else if (result.skinMPEExcess.albedo_wattsPerSqm <= 0.5) {
                                            return "#10c96d"
                                        }
                                        else if (result.skinMPEExcess.albedo_wattsPerSqm > 0.5 || result.skinMPEExcess.albedo_wattsPerSqm <= 1) {
                                            return "#ffa200"
                                        }
                                    })()
                                }}>

                                    <Flex justify="center">
                                        <strong style={{ color: "#ffffff" }}>
                                            {(() => {
                                                if (result.skinMPEExcess.albedo_wattsPerSqm > 1) {
                                                    return "The configuration that has been entered is Dangerous for the skin!"
                                                }
                                                else if (result.skinMPEExcess.albedo_wattsPerSqm <= 0.5) {
                                                    return "The configuration that has been entered is Safe for the skin!"
                                                }
                                                else if (result.skinMPEExcess.albedo_wattsPerSqm > 0.5 || result.skinMPEExcess.albedo_wattsPerSqm <= 1) {
                                                    return "The configuration that has been entered is Marginally Safe for the skin!"
                                                }
                                            })()}
                                        </strong>
                                    </Flex>
                                </Card>
                            </Card>
                        </>
                }, result.albedo.num === 100 ? null : {
                    key: 2,
                    label: "100% Albedo",
                    children:
                        <>
                            <Card style={{ marginBottom: "20px" }}>
                                <Divider><h4><img src={eye} width={50} style={{ marginRight: "5px" }} /><strong>EYE</strong></h4></Divider>
                                {/* Result for 100% Albedo */}
                                <Flex justify="center">
                                    <strong style={{ margin: '12px' }}>Result for 100% Albedo</strong>
                                </Flex>
                                <Flex justify='center' style={{ margin: '10px 10px' }}>
                                    <Space size='middle'>
                                        <Flex>
                                            <Card>
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>MPE</strong></Flex>
                                                    <Flex justify="center">
                                                        {(() => {
                                                            if (units === "joules") {
                                                                return result.MPE.joulesPerSqmPretty.eng + `J/m²`
                                                            }
                                                            else {
                                                                return result.MPE.wattsPerSqmPretty.eng + `W/m²`
                                                            }
                                                        })()}

                                                    </Flex>
                                                </Space>
                                            </Card>
                                        </Flex>

                                        <Flex>
                                            <Card>
                                                <Space direction="vertical">
                                                    <Flex><strong>Accessible Emission</strong></Flex>
                                                    <Flex justify="center">
                                                        {(() => {
                                                            if (units === "joules") {
                                                                return result.AE.joulesPerSqmPretty
                                                            }
                                                            else {
                                                                return result.AE.wattsPerSqmPretty
                                                            }
                                                        })()}
                                                    </Flex>
                                                </Space>
                                            </Card>
                                        </Flex>

                                        <Flex>
                                            <Card >
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>Eye Safety Ratio</strong></Flex>
                                                    <Flex justify="center"
                                                        style={(() => {
                                                            if (result.MPEExcess.wattsPerSqmPretty <= 0.5) {
                                                                return { color: 'green' }
                                                            }
                                                            else if (result.MPEExcess.wattsPerSqmPretty > 0.5 && result.MPEExcess.wattsPerSqmPretty <= 1) {
                                                                return { color: 'orange' }
                                                            } else {
                                                                return { color: 'red' }
                                                            }
                                                        })()}
                                                    ><strong>{result.MPEExcess.wattsPerSqmPretty}</strong></Flex>
                                                </Space>
                                            </Card>
                                        </Flex>
                                    </Space>
                                </Flex>
                                <Card style={{
                                    backgroundColor: (() => {
                                        if (result.MPEExcess.wattsPerSqm > 1) {
                                            return "#d82424"
                                        }
                                        else if (result.MPEExcess.wattsPerSqm <= 0.5) {
                                            return "#10c96d"
                                        }
                                        else if (result.MPEExcess.wattsPerSqm > 0.5 || result.MPEExcess.wattsPerSqm <= 1) {
                                            return "#ffa200"
                                        }
                                    })()
                                }}

                                >
                                    <Flex justify="center">
                                        <strong style={{ color: "#ffffff" }}>
                                            {(() => {
                                                if (result.MPEExcess.wattsPerSqm > 1) {
                                                    return "The configuration that has been entered is Dangerous for the eyes!"
                                                }
                                                else if (result.MPEExcess.wattsPerSqm <= 0.5) {
                                                    return "The configuration that has been entered is Safe for the eyes!"
                                                }
                                                else if (result.MPEExcess.wattsPerSqm > 0.5 || result.MPEExcess.wattsPerSqm <= 1) {
                                                    return "The configuration that has been entered is Marginally Safe for the eyes!"
                                                }
                                            })()}
                                        </strong>
                                    </Flex>
                                </Card>
                            </Card>
                            <Card style={{ marginBottom: "20px" }}>
                                <Divider><h4><img src={hand} width={50} style={{ marginRight: "5px" }} /><strong> SKIN </strong> </h4></Divider>
                                <Flex justify="center" gap={"middle"} style={{ margin: '10px 10px' }}>
                                    <Card>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>MPE</strong></Flex>
                                            <Flex justify="center">
                                                {(() => {
                                                    if (units === "joules") {
                                                        return result.MPEskin.joulesPerSqmPretty.eng + `J/m²`
                                                    }
                                                    else {
                                                        return result.MPEskin.wattsPerSqmPretty.eng + `W/m²`
                                                    }
                                                })()}
                                            </Flex>
                                        </Space>
                                    </Card>
                                    <Card>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>Skin AE</strong></Flex>
                                            <Flex justify="center">
                                                {(() => {
                                                    if (units === "joules") {
                                                        return result.skinAE.joulesPerSqmPretty
                                                    }
                                                    else {
                                                        return result.skinAE.wattsPerSqmPretty
                                                    }
                                                })()}
                                            </Flex>
                                        </Space>
                                    </Card>
                                    <Card>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>Skin Safety Ratio</strong></Flex>
                                            <Flex justify="center"
                                                style={(() => {
                                                    if (result.skinMPEExcess.wattsPerSqmPretty <= 0.5) {
                                                        return { color: 'green' }
                                                    }
                                                    else if (result.skinMPEExcess.wattsPerSqmPretty > 0.5 && result.skinMPEExcess.wattsPerSqmPretty <= 1) {
                                                        return { color: 'orange' }
                                                    } else {
                                                        return { color: 'red' }
                                                    }
                                                })()}
                                            ><strong>{result.skinMPEExcess.wattsPerSqmPretty}</strong></Flex>
                                        </Space>
                                    </Card>
                                </Flex>
                                <Card style={{
                                    backgroundColor: (() => {
                                        if (result.skinMPEExcess.wattsPerSqm > 1) {
                                            return "#d82424"
                                        }
                                        else if (result.skinMPEExcess.wattsPerSqm <= 0.5) {
                                            return "#10c96d"
                                        }
                                        else if (result.skinMPEExcess.wattsPerSqm > 0.5 || result.skinMPEExcess.wattsPerSqm <= 1) {
                                            return "#ffa200"
                                        }
                                    })()
                                }}>

                                    <Flex justify="center">
                                        <strong style={{ color: "#ffffff" }}>
                                            {(() => {

                                                if (result.skinMPEExcess.wattsPerSqm > 1) {
                                                    return "The configuration that has been entered is Dangerous for the skin!"
                                                }
                                                else if (result.skinMPEExcess.wattsPerSqm <= 0.5) {
                                                    return "The configuration that has been entered is Safe for the skin!"
                                                }
                                                else if (result.skinMPEExcess.wattsPerSqm > 0.5 || result.skinMPEExcess.wattsPerSqm <= 1) {
                                                    return "The configuration that has been entered is Marginally Safe for the skin!"
                                                }
                                            })()}
                                        </strong>
                                    </Flex>
                                </Card>
                            </Card>
                        </>
                }]} />
                <Card>
                    <Divider><h4><img src={beam} width={50} /> <strong>BEAM PROPERTIES</strong></h4></Divider>
                    <Flex justify={"center"} wrap="wrap"  >

                        <ResultCard>
                            <div onClick={() => { handleClick("NOHD") }} style={{cursor:"pointer"}}>
                                <Flex justify="center"><strong>NOHD</strong></Flex>
                                <Flex justify="center">
                                    {(() => {
                                        if (result?.NOHD?.isError === true || result?.NOHD?.isError === undefined || result?.NOHD?.isError === null) {
                                            return <Tooltip title={result?.NOHD?.message}>N/A</Tooltip>
                                        } else {
                                            return result?.NOHD?.value?.pretty
                                        }
                                    })()}
                                </Flex>
                            </div>
                        </ResultCard>

                        <ResultCard>
                            <div onClick={() => { handleClick("ENOHD") }} style={{cursor:"pointer"}}>
                                <Flex justify="center"><strong>ENOHD</strong></Flex>
                                {/* <Flex justify="center">{result?.ENOHD?.val !== null ? result.ENOHD.value.pretty : 'N/A'}</Flex> */}
                                <Flex justify="center">
                                    {(() => {
                                        if (result?.ENOHD?.isError === true || result?.ENOHD?.isError === undefined || result?.ENOHD?.isError === null) {
                                            return <Tooltip title='ENOHD is only applicable for wavelengths  between 302.5nm ~ 10⁶nm'>N/A</Tooltip>
                                        } else {
                                            return result?.ENOHD?.value?.pretty
                                        }
                                    })()}
                                </Flex>
                            </div>
                        </ResultCard>

                        <ResultCard>
                            <div onClick={() => { handleClick("Spot Size") }} style={{cursor:"pointer"}}>
                            <Flex justify="center"><strong>Spot Size</strong></Flex>
                            <Flex justify="center">{result.outputBeamDiameter}</Flex>
                            </div>
                        </ResultCard>

                        <ResultCard>
                            <div onClick={()=>{handleClick("limit aperture")}} style={{cursor:"pointer"}}>
                                <Flex justify="center"><strong>Limit Aperture</strong></Flex>
                                <Flex justify="center">{result.limitAperture.eye + ' mm'}</Flex>
                                <Flex justify="center">{result.limitAperture.skin + ' mm'}</Flex>
                            </div>
                        </ResultCard>

                        <ResultCard>
                            <div onClick={()=>{handleClick("applicable beam area")}} style={{cursor:"pointer"}}>
                                <Space direction="vertical">
                                    <Flex justify="center"><strong>Beam Area</strong></Flex>
                                    <Flex justify="center">
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>Actual</strong></Flex>
                                            <Flex>{result.beamArea.actualPretty}</Flex>
                                        </Space>
                                        <Divider orientation="center" type="vertical" />
                                        <Space direction="vertical">
                                            <Flex><strong>Applicable</strong></Flex>
                                            <Flex>{result.beamArea.applicablePretty}</Flex>
                                        </Space>
                                    </Flex>
                                </Space>
                            </div>
                        </ResultCard>


                        <ResultCard style={{ width: "200px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div onClick={() => { handleClick("spectrum") }} style={{cursor:"pointer"}}>
                                <strong> Spectrum</strong>
                                <Row justify={"center"}>
                                    <div style={{
                                        width: "30px",
                                        height: "30px",
                                        backgroundColor: (() => {
                                            if (result.spectrum.range == "Infrared") {
                                                return "maroon"
                                            }
                                            else if (result.spectrum.range == "Ultraviolet") {
                                                return "purple"
                                            }
                                            return "rgb(" + result.spectrum.rgb + ")"
                                        })(),
                                        borderRadius: 100,
                                        ustifyContent: "center"
                                    }}>
                                    </div>
                                </Row>
                            <Row justify={"center"}>{result.spectrum.region}</Row>
                            </div>
                        </ResultCard>
                    </Flex>
                </Card>

            </>
        )
    }, {
        key: 2,
        label: "More information",
        children:
            <>
                <Divider>Correction Factors and Break Points</Divider>
                <Flex justify="center">
                    <Space>
                        {result.correctionFactors.map((cf => {
                            return (
                                <Card>
                                    <Space direction="vertical">
                                        <Flex justify="center"><strong>{cf.name}</strong></Flex>
                                        <Flex justify="center">{cf.pretty}</Flex>
                                    </Space>
                                </Card>
                            )
                        }))}
                    </Space>
                </Flex>
                <Divider>Angular Parameters</Divider>
                <Flex justify="center">
                    <Space>
                        <Card>
                            <Space direction="vertical">
                                <Flex justify="center"><strong>Angular Subtense (diffused)</strong></Flex>
                                <Flex justify="center">{result.angularSubtense}</Flex>
                            </Space>
                        </Card>

                        {/* <Card>
                            <Space direction="vertical">
                                <Flex justify="center"><strong>Angle of Acceptance</strong></Flex>
                                <Flex justify="center">{result.MPE.angleOfAcceptancePretty}</Flex>
                            </Space>
                        </Card> */}
                        {/*
                        <Card>
                            <Space direction="vertical">
                                <Flex justify="center"><strong>Alpha max</strong></Flex>
                                <Flex justify="center">1.3rad</Flex>
                            </Space>
                        </Card> */}
                    </Space>

                </Flex>

            </>
    }, {
        key: 3,
        label: "Laser Class Details",
        children:
            <>
                <Divider orientation="center">  Accessible Emission Limits </Divider>
                {/* <LaserClassResultsV2 laserClass={result.laserClass} condition={1} />
                    <LaserClassResultsV2 laserClass={result.laserClass} condition={3} /> */}

                <LaserClassResultsDiffuseReflective result={result} condition={1} laserOperation={laserOperation}/>
                <br />
                <LaserClassResultsDiffuseReflective result={result} condition={3} laserOperation={laserOperation}/>

                <LaserClassVerdict laserClass={result.Class} />
            </>
    }, {
        key: 4,
        label: "Laser Protective Eyewear",
        children: <>
            <EyewearResults props={props} />
            <Row justify={'center'}>
                <Eyewear />
            </Row></>
    }]} />);
}
