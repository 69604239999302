import Axios from "axios"
import {React, useContext, useState} from "react"
import { Form, Input, Card, Flex, Layout, Button, Typography, Space } from "antd"
import miniIcon from '../../assets/Logo/logosidetext.png'
import { Navigate } from "react-router-dom"
import { EnvironmentContext } from "../../routes/EnvironmentContext"
import { useNavigate } from "react-router-dom"

const { Title } = Typography

const {Content} = Layout

const ForgotPassword =() =>{
    const navigate = useNavigate()
    const { setEmail, setPage, email, setOTP } = useContext(EnvironmentContext);
    const [isSpinning, setIsSpinning] = useState(false)

    function navigateToOTP(value){
        const {email} = form.getFieldValue()
        sessionStorage.setItem('userEmail', email);
        setIsSpinning(true)

        Axios({
            method: 'post',
            url: 'http://localhost:8000/sendCode',
            data: {Email: email},
            withCredentials: true
        })
        .then((response)=>{
            setIsSpinning(false)
            navigate('/verifyCode')
            console.log(response)
        })
        .catch((error)=>{
            console.error(error)
            setIsSpinning(false)
            form.setFields([
                {
                    name: 'email',
                    errors: ["Email not registered"]
                }
            ])
        })
            
    }
    const [form] = Form.useForm();
    return (
        <Content style={{ backgroundColor: '#FFFFFF',}}>
            <Flex  justify='center' style={{ width: "100%"}}>
                <Card 
                    style={{
                        width: 500,
                        fontWeight: '500',
                        border:"none",
                        marginTop:"100px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        padding: "10px 0px 20px 0px"
                       
                    }}
                    // cover={
                    //     <Flex justify='center' align='center' style={{ marginBottom: "60px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                    //         <img
                    //             justify='center' align='center'
                    //             src={miniIcon}
                    //             style={{ width: "90%", justifySelf: "center"}}
                    //         />
                    //     </Flex> 
                    // }
                    >
                     <Typography.Title >
                      Forgot your password
                    </Typography.Title>
                    {/* <Typography.Title style={{justifySelf:"center"}}>
                       Enter your registered email address
                    </Typography.Title> */}
                    <Typography>
                    Please enter your registered email address for receiving the verification code.
                    </Typography>
                    <Form   
                        form={form}
                        onFinish={()=>navigateToOTP()}
                        style={{marginTop:"20px"}}>
                        
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your email!',
                                }
                            ]}
                            >
                            <Input size="large" placeholder="Enter your email address"/>
                        </Form.Item>
                        <Space direction="vertical" style={{width:"100%", marginTop:"20px"}}>
                            <Button htmlType="submit" type="primary" block loading={isSpinning}>
                                Send Code
                            </Button>
                            <Button block onClick={()=>navigate('/login')}>
                                Cancel
                            </Button>
                        </Space>
                    </Form>
                </Card>
            </Flex>
        </Content>

    )
} 

export default ForgotPassword