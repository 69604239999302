const decimalForm = (ParamsToConvert) => {
    console.log(`Params to Convert: `, ParamsToConvert)
    // if (ParamsToConvert.num <= 0) throw TypeError("StandardParams entered is a zero or a negative value");
    switch (ParamsToConvert.unit) {
        case 'M': {
            return Number(ParamsToConvert.num * 1e6);
        }
        case 'k': {
            return Number(ParamsToConvert.num * 1e3);
        }
        case false: {
            return Number(ParamsToConvert.num);
        }
        case 'c': {
            return Number(ParamsToConvert.num * 1e-2);
        }
        case 'm': {
            return Number(ParamsToConvert.num * 1e-3);
        }
        case 'u': {
            return Number(ParamsToConvert.num * 1e-6);
        }
        case 'n': {
            return Number(ParamsToConvert.num * 1e-9);
        }
        case 'nm': {
            return Number(ParamsToConvert.num * 1e-9);
        }
        case 'p': {
            return Number(ParamsToConvert.num * 1e-12);
        }
        case 'f': {
            return Number(ParamsToConvert.num * 1e-15);
        }
    }
};

export default decimalForm