import React from 'react';
import { Button, Result, Flex, Card } from 'antd';
import { NavLink } from 'react-router-dom';

const ForgotPasswordSuccess = () => (
  
  <Flex justify='center'>
    <Card   
      style={{
        width: 500,
        fontWeight: '500',
        border:"none",
        marginTop:"100px",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      }}>
      <Result
        status="success"
        title={<h1>Success!</h1>}
        subTitle="Your password has been successfully changed."
        extra={[
          <>
       
          </>
        ]}
      />
         <NavLink to='/login'>
            <Button type="primary" key="console" block>
            Continue to Login
            </Button>
          </NavLink>
    </Card>
  </Flex>
);
export default ForgotPasswordSuccess;