import { DownloadOutlined, CloseOutlined, SettingOutlined, SaveTwoTone, FullscreenExitOutlined, FullscreenOutlined} from '@ant-design/icons'
import { Button, Card, Col, Flex, Form, Radio, Row, Select, Space, message, Tooltip, Modal, Input, Typography, Spin } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import Field from '../../../components/Field'
import { LaserProfileModal } from '../../../components/LaserProfile/LaserProfileModal'
import axios from 'axios'
import Cookies from 'js-cookie'
import { DRResultCW } from './Results/DiffuseReflective_Results/DRResultCW'
import { DRCWFields } from './DiffuseReflective/DRCWFields'
import { DRResultSinglePulse } from './Results/DiffuseReflective_Results/DRResultSinglePulse'
import { AddLaserProfile } from '../../../components/LaserProfile/AddLaserProfile'
import { DRSPFields } from './DiffuseReflective/DRSPFields'
import { DRRPFields } from './DiffuseReflective/DRRPFields'
import { DRResultRepetitivelyPulsed } from './Results/DiffuseReflective_Results/DRResultRepetitivelyPulsedV2'
import FetchLaserProfile from '../../../components/MyLaserProfileFetch'
import FetchCalculations from '../../../components/FetchCalculations'
import { CalculationStatusModal } from '../../../components/CalculationStatusModal'
import { ReactComponent as Bars } from '../../../assets/Bars.svg'
import { ReactComponent as Wedges } from '../../../assets/Wedges.svg'
import { ConfirmCalculateModal } from '../../../components/ConfirmCalculateModal'
import Loader from '../../../assets/Loader'
import './Calcs.css'
import { ResultsPDF } from '../../../components/PDF/ResultsPDFDiffuse'
import { PDFDownloadLink } from "@react-pdf/renderer";
import SettingsModal from './SettingsModal'
import { FooterHandler } from '../../../components/Footer/Footer'

import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import { debounce } from "lodash";

const { TextArea } = Input;

const defaultValues = {

    calculatorType: "DiffuseReflective",
    laserOperation: 'diffuseReflectiveCW',
    sourceType: 'point',

    diffuseReflective: {

        albedo: { num: null },
        distance2: { unit: false },

    },
    diffuseReflectiveCW: {
        wavelength: { unit: 'n' },
        time: { unit: false },
        power: { unit: false },
        beamDiameter: { num: null },
        beamDivergence: { unit: 'm' },
        albedo: { num: null },
        distance: { unit: false },
        distance2: { unit: false },

    },
    diffuseReflectiveSP: {
        wavelength: { unit: 'n' },
        time: { unit: false },
        beamDiameter: { num: null },
        beamDivergence: { unit: 'm' },
        albedo: { num: null },
        distance: { unit: false },
        distance2: { unit: false },
        energy: { unit: false },
        peakPower: { unit: false },
        pulseDuration: { unit: 'm' },
        beamOutputUnit: 'energy',
    },
    diffuseReflectiveRP: {
        wavelength: { unit: 'n' },
        time: { unit: false },
        beamDiameter: { num: null },
        beamDivergence: { unit: 'm' },
        albedo: { num: null },
        distance: { unit: false },
        distance2: { unit: false },
        energy: { unit: false },
        peakPower: { unit: false },
        beamOutputUnit: 'energy',
        pulseDuration: { unit: 'm' },
        averagePower: { unit: false },
        pulseFrequency: { unit: false }
    }

}




const DiffuseReflectiveCalculator = ({ isLocal, myUser, setMyUser }) => {
    const [result, setResult] = useState(null)
    const [form] = Form.useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [units, setunits] = useState("joules")
    const [submitProfileIsLoading, setSubmitProfileIsLoading] = useState(false)
    const [laserProfileSaveVisible, setLaserProfileSaveVisible] = useState(false)
    const [laserProfileForm] = Form.useForm()
    const [laserprofiledebug, setlaserprofiledebug] = useState(null)
    const [messageApi, contextHolder] = message.useMessage()
    const navigate = useNavigate()
    const [myDefaultValues, setMyDefaultValues] = useState(defaultValues)
    const [isUsingLaserProfile, setIsUsingLaserProfile] = useState(false)
    const [form2] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form3] = Form.useForm()
    const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(myUser, isLocal)
    const [numberOfCalcs2, setNumberOfCalcs2] = useState(null)
    const { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial } = FetchCalculations(isLocal, myUser, numberOfCalcs2)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [spinning, setSpinning] = useState(false);

    const [openBuy, setOpenBuy] = useState(false)
    const [showSettingsModal, setShowSettingsModal] = useState(false)
    const [ isFullScreen, setisFullScreen] = useState(false)
    const [laserName, setLaserName] = useState("")
    const [laserForm] = Form.useForm()
    const tempName = useRef("");

    const toggleFullscreen = () =>{
        setisFullScreen((prevFullScreen => !prevFullScreen))
    }

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: '#save-laser',
                popover: {
                    title: 'Save Laser',
                    description: 'Clicking this button will save the laser after providing a description for it.'
                }
            }
        ]
    })

    {/*Lets the user calculate if numberofcalculations !== 0 */ }
    const checkStatus = () => {
        {/*Prompts user to buy more calculations if numberofcalculations === 0 */ }
        if (isDisabled === true && isSubscribed === false && isFreeTrial === null) {
           
            setOpenBuy(true)
        }
        else if (isDisabled === false || isSubscribed === true || isFreeTrial === true) {
            {/*Prompts the user to proceed with the calculation */ }
            const { hide_confirmation } = JSON.parse(Cookies.get('RTLSS_SETTINGS'))
            if (hide_confirmation) {
                submitForm()
            } else {
                showPopconfirm(true)
            }
        }
    }

    const handleCancelCheckStatus = () => {
        {/**Close calculation status modal */ }
        setOpenBuy(false)
    };

    useEffect(() => {
        if (numberOfCalcs !== null) {
            setSpinning(false);
        } else {
            setSpinning(true);
        }
    }, [numberOfCalcs]);

    const showPopconfirm = () => {
        setIsConfirmOpen(true);
    };
    const handleCancelPopConfirm = () => {
        setIsConfirmOpen(false);
    };

    const decimalForm = ({ unit, num }) => {
        switch (unit) {
            case 'T': {
                return num * 1e12
            }
            case 'G': {
                return num * 1e9
            }
            case 'M': {
                return num * 1e6
            }
            case 'k': {
                return num * 1e3
            }
            case false: {
                return num
            }
            case 'm': {
                return num * 1e-3
            }
            case 'u': {
                return num * 1e-6
            }
            case 'n': {
                return num * 1e-9
            }
            case 'p': {
                return num * 1e-12
            }
            case 'f': {
                return num * 1e-15
            }
        }
    }


    /**
     * This function will return either a span of 12 when results card is present, and 24 if result card is absent.
     * @returns 
    */
    const lg = (before, after) => {
        if (result === null) return before
        return after
    }

    let data = form.getFieldsValue()
    const submitForm = () => {
        // setResult(true)
        setIsConfirmOpen(false);
        setIsSubmitting(true);
        const data = form.getFieldsValue()
        axios({
            method: 'post',
            url: (() => {
                if (isLocal) {
                    return 'http://localhost:8000/calculate'
                } else {
                    return 'api/calculate'
                }
            })(),
            withCredentials:true, 
            headers: { Authorization: `Bearer ${Cookies.get('RTLSS_TOKEN')}` },
            data: { data, numberOfCalcs, isFreeTrial, isSubscribed }
        })
            .then((response) => {
                setIsSubmitting(false);
                console.log(response.data.calcResult)
                setResult(response.data.calcResult)
                setNumberOfCalcs2(response.data.newNumberOfCalculations)
            })
            .catch((err) => {
                navigate('/logout')
            })
    }

    const formValuesChanged = () => {
        setResult(null)
        form2.resetFields()
    }

    const onUnitChange = (e) => {
        setunits(e.target.value)
    }


    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
            duration: 4
        });
    };

    const showError = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: 4
        })
    }
    useEffect(() => {
        form.resetFields()
    }, [myDefaultValues])
    const handleSaveLaser = () => {
        setLaserProfileSaveVisible(true)
    }

    const handleConfirmSaveLaser = () => {
        setSubmitProfileIsLoading(true)
        laserProfileForm.setFieldValue(['laserProfile', 'data'], form.getFieldsValue())
        setlaserprofiledebug(JSON.stringify(laserProfileForm.getFieldsValue(), null, 2))
        // Save this to database.
        axios({
            method: 'post',
            url: (() => {
                if (isLocal === true) {
                    return 'http://localhost:8000/add-profile'
                } else {
                    return 'api/add-profile'
                }
            })(),
            withCredentials: true,
            data: { data: laserProfileForm.getFieldsValue(), calculatorType: defaultValues.calculatorType }
        }).then((res) => {
            success(res.data.message)
            setMyUser({ ...myUser, laserProfile: res.data.response.rows })
        }).catch((err) => {
            showError(err.response.data.message)
        }).finally(() => {
            setLaserProfileSaveVisible(false)
            setSubmitProfileIsLoading(false)
            laserProfileForm.resetFields()
        })

    }
    const showModal = () => {
        setIsModalOpen(true);

    }

    const handleCancel = () => {
        form3.resetFields()
        setIsModalOpen(false);
    }
    const handleOk = () => {
        setLoading(true);
        let allReports = { ...form3.getFieldValue(), ...form.getFieldsValue(), result }
        axios({
            method: 'post',
            url: (() => {
                if (isLocal === true) {
                    return 'http://localhost:8000/report'
                }
                else {
                    return 'api/report'
                }
            })(),
            withCredentials: true,
            data: { data: allReports }
        }).then((res) => {
            success(res.data.message)

        }).catch((err) => {
            console.error(err)
            showError(err.response.data.message)
        }).finally(() => {
            setLoading(false);
            form3.resetFields()
            setIsModalOpen(false)
        })

    }
    
    const handleInputChange = debounce((value) => {
        console.log("ey", value)
        setLaserName(value);
    }, 300)

    const handleDownload = () => {
        if (result) {
            const date = new Date().toLocaleString().split(',')[0]
            const pdfContent = (
                <ResultsPDF result={result} date={date} form={form.getFieldsValue()} laserName={laserName}/>
            )
            return (
                <Space>
                    <PDFDownloadLink document={pdfContent} fileName={`${laserName || "Diffuse-Reflective-Results"}.pdf`}>
                        {({ blob, url, loading, error }) => (
                            <Button 
                                icon={<DownloadOutlined />}
                                type="primary" 
                                disabled={!laserName.trim()} 
                                onClick={()=>{
                                laserForm.resetFields() 
                                setLaserName("")
                                laserForm.resetFields()
                                }}
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} >
                            Download Results
                            </Button>
                        )}
                    </PDFDownloadLink>

                    {/* <PDFDownloadLink document={
                <Document>
                  <Page size="LETTER" style={stylez}>
                    <Svg viewBox={`0 0 595.3 841.9`}>
                      {svgComponent}
                    </Svg>
    
                  </Page>
                </Document>
              }>
                {({ blob, url, loading, error }) => (
                  loading ? <Spin /> : <Button icon={<DownloadOutlined />}>Download Results V2</Button>
                )}
              </PDFDownloadLink> */}
                </Space>

            )
        }
    };


    return (
        <>
            {contextHolder}

            <AddLaserProfile laserProfileForm={laserProfileForm}
                laserProfileSaveVisible={laserProfileSaveVisible}
                setLaserProfileSaveVisible={setLaserProfileSaveVisible}
                submitProfileIsLoading={submitProfileIsLoading}
                handleConfirmSaveLaser={handleConfirmSaveLaser} />

            <Spin spinning={spinning} size='large' indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", right: "50%", top: "60%", transform: "translate(-41%, -50%)" }} />}>
                <div class="calcs">
                    <Spin spinning={isSubmitting} indicator={<Loader />} style={{ zIndex: 10000, fontSize: "500px" }}>
                        {numberOfCalcs !== null ? (
                            <>
                                {/* <Row justify={'center'}>
                        <h2 style={{textAlign: "center"}}>Diffuse Reflective Calculator</h2>
                    </Row> */}

                                <Row justify={"center"}>
                                    <Col lg={result === null ? 24 : 12} sm={24} 
                                        style={{ 
                                            padding: '15px 20px',
                                            display: (()=>{
                                                if(isFullScreen){
                                                    return "none"
                                                }
                                            })()
                                        }}>
                                        <Row justify={'center'}>
                                            <Col lg={result === null ? 10 : 18} md={24}>
                                                <Card title={<h4>Diffuse Reflective Calculator</h4>} style={{ width: '100%', display: 'block', marginTop: "60px" }}
                                                    extra=
                                                    {result != null ? (
                                                        <>
                                                            <Tooltip
                                                                mouseEnterDelay={0.5}
                                                                destroyTooltipOnHide
                                                                showArrow
                                                                placement='left'
                                                                title={`Click me to save this laser setup.`}>
                                                                <Button id='save-laser' type='text' onClick={handleSaveLaser} icon={<SaveTwoTone />} />
                                                            </Tooltip>
                                                            <Button
                                                                type='text'
                                                                icon={<SettingOutlined />}
                                                                onClick={() => {
                                                                    setShowSettingsModal(true)
                                                                }}
                                                            />
                                                            <Button
                                                                type="text"
                                                                icon={<CloseOutlined />}
                                                            />
                                                        </>

                                                    ) : (
                                                        <Button
                                                            type='text'
                                                            icon={<SettingOutlined />}
                                                            onClick={() => {
                                                                setShowSettingsModal(true)
                                                            }}
                                                        />
                                                    )}  >

                                                    <Form form={form2}
                                                        labelCol={{ span: 8 }}  >
                                                        <Form.Item label='Saved Laser' name='laserProfile'>
                                                            <Select placeholder='Select from saved lasers'
                                                                options={(() => {
                                                                    if (myLaserProfile?.laserProfile) {
                                                                        const { laserProfile } = myLaserProfile
                                                                        let filterProfile = laserProfile.filter(laserProfile => laserProfile.calculatortype === "DiffuseReflective")
                                                                        return filterProfile.map((profile, index) => ({
                                                                            key: index,
                                                                            label: profile.description,
                                                                            value: JSON.stringify(profile.data)
                                                                        }))
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })()}
                                                                disabled={(() => {
                                                                    if (myLaserProfile?.length !== 0) {
                                                                        return false
                                                                    } else {
                                                                        return true
                                                                    }
                                                                })()}
                                                                onChange={(props) => {
                                                                    setMyDefaultValues({ ...defaultValues, ...JSON.parse(props) })
                                                                    setIsUsingLaserProfile(true)
                                                                    setResult(null)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                    <Form
                                                        onValuesChange={formValuesChanged}
                                                        form={form}
                                                        initialValues={myDefaultValues}
                                                        name='laserSafety'
                                                        onFinish={checkStatus}
                                                        labelCol={{ span: 8 }}>

                                                        <Form.Item noStyle name={['laserOperation']} />

                                                        <Form.Item
                                                            name={['laserOperation']}
                                                            label='Laser Operation'>
                                                            <Radio.Group optionType='default'>
                                                                <Space direction='horizontal'>
                                                                    <Radio.Button value='diffuseReflectiveCW'>CW</Radio.Button>
                                                                    <Radio.Button value='diffuseReflectiveSinglePulse'>Single Pulse</Radio.Button>
                                                                    <Radio.Button value='diffuseReflectiveRepetitivelyPulsed'>Repetitively Pulsed</Radio.Button>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={['sourceType']}
                                                            noStyle>
                                                            {/* <Radio.Group optionType='default'>
                                                    <Space direction='horiztonal'>
                                                        <Radio.Button value='point'>Point Source</Radio.Button>
                                                        <Radio.Button value='extended'>Extended Source</Radio.Button>
                                                    </Space>
                                                </Radio.Group> */}
                                                        </Form.Item>

                                                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.laserOperation !== curr.laserOperation}>
                                                            {({ getFieldValue }) => {
                                                                let laserOperation = getFieldValue(['laserOperation'])
                                                                if (laserOperation === 'diffuseReflectiveCW') {
                                                                    return (
                                                                        <DRCWFields />
                                                                    )
                                                                }
                                                                else if (laserOperation === 'diffuseReflectiveSinglePulse') {
                                                                    return (
                                                                        <DRSPFields form={form} />
                                                                    )
                                                                }
                                                                else if (laserOperation === 'diffuseReflectiveRepetitivelyPulsed') {
                                                                    return (
                                                                        <DRRPFields form={form} />
                                                                    )
                                                                }
                                                            }}
                                                        </Form.Item>

                                                        <Card title={'Surface Params'}>
                                                            {/* Albedo */}
                                                            <Field name={['diffuseReflective', 'albedo']}
                                                                max={100}
                                                                unit='%'
                                                                label={'Albedo'}
                                                                toolTipText={'This is the reflectance of the dispersing surface.'}
                                                                placeholder={`Enter dispersing surface's reflectance (0-100%)`}
                                                            />

                                                            {/* Distance Between Disperser */}
                                                            <Field
                                                                name={['diffuseReflective', 'distance2']}
                                                                denoms={['m', 'c', false]}
                                                                unit='m'
                                                                label={`Surface to Eye Distance`}
                                                                toolTipText={`This is the distance between the dispersing surface and the eye`}
                                                                placeholder={`Enter distance between disperser and eye`}
                                                            />
                                                        </Card>

                                                        {/*  */}
                                                        <div class="hover-underline-animation2"
                                                            style={{ width: "100%" }}>
                                                            <Button
                                                                type='primary'
                                                                block
                                                                htmlType='submit'
                                                                style={{
                                                                    margin: "24px 0 0",
                                                                    background: '#1d1f8c',

                                                                }}
                                                                loading={isSubmitting}>
                                                                Calculate
                                                            </Button>
                                                        </div>
                                                        {isSubscribed === false ? (
                                                            <>
                                                                <Row justify={'end'} style={{ marginTop: "1%" }}>
                                                                    <Typography.Text type='secondary' style={{ fontSize: "20px" }}>
                                                                        {isFreeTrial ? <i>Free Trial</i> : <i>Remaining Calculations: <strong style={{ color: "black" }}>{numberOfCalcs}</strong></i>}
                                                                    </Typography.Text>
                                                                </Row>
                                                                {numberOfCalcs < 6 && !isFreeTrial ? (
                                                                    <Row justify={'end'} style={{ marginTop: "1%" }}><Typography.Text type='secondary' style={{ fontSize: "15px" }}>Running low on calculations? Get more calculations <NavLink to={`/home?defKey=${"4"}&handleClick=${"billing"}`}>here!</NavLink></Typography.Text></Row>
                                                                ) : null}
                                                            </>
                                                        ) :
                                                            <Row justify={'end'} style={{ marginTop: "1%" }}>
                                                                <Typography.Text type='secondary' style={{ fontSize: "20px" }}><i>Unlimited Calculations</i></Typography.Text>
                                                            </Row>
                                                        }

                                                        {result && (
                                                            <Flex justify='right'>
                                                                <Button
                                                                    type='text'
                                                                    onClick={() => {
                                                                        driverObj.drive()
                                                                    }}
                                                                >
                                                                    <Typography.Text italic>Do you want to save this laser?</Typography.Text>
                                                                </Button>
                                                            </Flex>
                                                        )}

                                                    </Form>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {result !== null ? (
                                        <Col lg={12} sm={24} style={{ padding: '15px 20px', width: "100vw" }}>
                                            <Row justify={"center"} >
                                                <Card
                                                    title='Calculation Results'
                                                    style={{ width: "90vw", marginTop: "60px" }}
                                                    extra={[
                                                    <Space>
                                                        <Radio.Group
                                                            onChange={onUnitChange}
                                                            value={units}
                                                            buttonStyle="solid"
                                                        >
                                                            <Radio.Button value="joules">Joules</Radio.Button>
                                                            <Radio.Button value="watts">Watts</Radio.Button>
                                                        </Radio.Group>
                                                        <Tooltip destroyTooltipOnHide placement='top' showArrow mouseEnterDelay={0.5} title="Click me to hide inputs." zIndex={1}>
                                                            <Button icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={toggleFullscreen} />
                                                        </Tooltip>
                                                    </Space>
                                                    ]}
                                                >

                                                    {result.laserOperation === `diffuseReflectiveCW` ? (
                                                        <DRResultCW result={result.data} units={units} laserOperation={form.getFieldsValue().laserOperation} data={data} />
                                                    ) : (result.laserOperation === `diffuseReflectiveSinglePulse` ? (
                                                        <DRResultSinglePulse result={result.data} units={units} laserOperation={form.getFieldsValue().laserOperation} data={data} />
                                                    ) : (result.laserOperation === `diffuseReflectiveRepetitivelyPulsed` ? (
                                                        <DRResultRepetitivelyPulsed result={result.data} units={units} laserOperation={form.getFieldsValue().laserOperation} data={data} />
                                                    ) : null))}
                                                    <Row justify={"end"} align={"top"} style={{ margin: "25px 5px 5px 0", display: "flex" }}>
                                                        <Form  form={laserForm}>
                                                            <Form.Item name='name'>
                                                                <Input 
                                                                placeholder='Enter laser setup name'
                                                                value={tempName}
                                                                onChange={(e)=> {
                                                                    tempName.current = e.target.value;
                                                                    handleInputChange(e.target.value)}}
                                                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, alignSelf:"center" }} 
                                                                />
                                                            </Form.Item>
                                                        </Form>
                                                        {handleDownload()}
                                                    </Row>
                                                </Card>
                                                <Button block type='primary' danger style={{ marginTop: 10, width: "100%", whiteSpace: "normal", height: "auto", marginBottom:"50px"  }} onClick={showModal}>Is there something wrong with the results? Click here to report a bug.</Button>

                                                <Modal title="Report a Bug" onCancel={handleCancel} open={isModalOpen} footer={[null]}>
                                                    <Form form={form3} onFinish={handleOk}>
                                                        <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input a description." },]}>
                                                            <TextArea
                                                                placeholder=""
                                                                style={{
                                                                    height: 120,
                                                                    width: 350,
                                                                    resize: 'none',
                                                                }} />
                                                        </Form.Item>
                                                        <Flex justify='flex-end' align='flex-end' style={{ marginBottom: -35 }}>
                                                            <Form.Item>
                                                                <Button onClick={handleCancel}> Cancel</Button>
                                                                <Button type='primary' htmlType="submit" loading={loading} style={{ marginLeft: 10 }} >Submit</Button>
                                                            </Form.Item>
                                                        </Flex>
                                                    </Form>
                                                </Modal>
                                            </Row>
                                        </Col>
                                    ) : null}
                                </Row>
                            </>
                        ) : null}
                    </Spin>
                </div>
                <FooterHandler />
            </Spin>
            {/* Confirm Calculate */}
            <ConfirmCalculateModal isConfirmOpen={isConfirmOpen} handleCancelPopConfirm={handleCancelPopConfirm} submitForm={submitForm}></ConfirmCalculateModal>
            {/* Settings Modal */}
            <SettingsModal showSettingsModal={showSettingsModal} setShowSettingsModal={setShowSettingsModal} />
            <CalculationStatusModal openBuy={openBuy} handleCancelCheckStatus={handleCancelCheckStatus}></CalculationStatusModal>
        </>
    )
}

export default DiffuseReflectiveCalculator


