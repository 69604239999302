import { Modal, Button, Form, Checkbox, Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import Cookies from 'js-cookie'

const rtllSettings = { expires: 365 }

export function ConfirmCalculateModal(props) {
  // var input = document.getElementById("myInput")

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (props.isConfirmOpen && event.key === 'Enter') {
        props.submitForm();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.isConfirmOpen, props.submitForm]);


  return (
    <Modal
      title="Confirmation"
      description="Are you sure you want to calculate?" // placement="topLeft"
      open={props.isConfirmOpen}
      onOk={props.submitForm} // confirmLoading={isSubmitting}
      onCancel={props.handleCancelPopConfirm}
    >
      Are you sure you want to calculate

      <Flex justify='right'>
        <Form.Item
          label={`Don't show again`}>
          <Checkbox
            onChange={(e) => {
              if (Cookies.get('RTLSS_SETTINGS')) {
              
                if (e.target.checked) {
                  Cookies.set('RTLSS_SETTINGS', JSON.stringify({ ...JSON.parse(Cookies.get('RTLSS_SETTINGS')), hide_confirmation: true }), rtllSettings)
                } else {
                  Cookies.set('RTLSS_SETTINGS', JSON.stringify({ ...JSON.parse(Cookies.get('RTLSS_SETTINGS')), hide_confirmation: false }), rtllSettings)
                }
              } else {
               
                Cookies.set('RTLSS_SETTINGS', JSON.stringify({}), rtllSettings)
              }

            }} />
        </Form.Item>
      </Flex>
      <Flex>
        <Typography.Text type='secondary'>
          RealTimeLSS is for informational purposes only and does not provide legal, technical, or professional advice. For safety compliance decisions, 
          consult a certified Lasermet Laser Safety Officer or another qualified professional. Click here to view the full  <a href="/terms-and-conditions" target="_blank" rel="noreferrer">terms and conditions</a>, or click here to request a consultation with a certified Lasermet Laser Safety Officer
        </Typography.Text>
      </Flex>

    </Modal>);
}
