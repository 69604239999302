import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nav_bar.css';
import {DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation} from 'react-router-dom';
import { message, Button } from 'antd';
import axios from 'axios'
import RTLLSS from '../assets/Logo/logosidetext.png'
import DiffuseReflection2 from '../assets/MenuCalculators/Diffuse Reflection P.png'
import PointnadExtendedP from '../assets/MenuCalculators/Laser P.png'
import FiberOpticP from '../assets/MenuCalculators/Fibre Optics P.png'
import LookupTableP from '../assets/MenuCalculators/LookUp Table P.png'


function Nav_bar({myUser, setMyUser, isLocal, myLaserProfile }) {
  
  const [laserProfileVisibility, setLaserProfileVisibility] = useState(false)
  const [showEdit, setshowEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  const [expand, setExpand]= useState(false)


  const handleDeleteProfile = (props) => {
    setDeleteIsLoading(true)
    let laserIDToDelete = props.currentTarget.value
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/delete-laser'
        } else {
          return 'api/delete-laser'
        }
      })(),
      data: { laserIDToDelete: laserIDToDelete, accountID: myUser.accountID }
    })
      .then((response) => {
        success(response.data.message)
        setMyUser({ ...myUser, laserProfile: response.data.data })
      })
      .catch((err) => {
        isError(err.data.message)
      })
      .finally(() => {
        setDeleteIsLoading(false)
      })
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const show = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    messageApi.open({

      type: 'success',
      content: 'This is a prompt message for success, and it will disappear in 3 seconds',
      duration: 3,

    });
    setIsModalOpen(false);
  };

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    })
  }

  const isError = (message) => {
    messageApi.open({
      type: 'error',
      conntent: message,
      duration: 4
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleExpand = () => {
    setExpand((prevExpand) => !prevExpand)
  
  }
  
  const location = useLocation();
  useEffect(() => {
    setExpand(false)
    
  }, [location.pathname])
  
  useEffect(() => {
    window.addEventListener("click", function(event) {
      if (event.target.id !== "clicker" && event.target.id !== "test" && event.target.id !== "nav") {
        setExpand(false);
      }
    })
  },[])

  return (
    <>
      {contextHolder}
    
        <>
        <Navbar
          collapseOnSelect
          expand="md"
          className={ expand && location.pathname !=="/home" && location.pathname !=="/" ? "bg-body expandNav" 
            :  !expand && location.pathname !=="/home" && location.pathname !=="/" ? "bg-body collapseNav" 
            : location.pathname === "/home" ? "bg-body" 
            : location.pathname === "/" ? "bg-body" 
            : "bg-body"}
          class="row "
          id="nav"
          fixed="top"
          expanded= "true"
          style={{justifyContent:"center"}}
          // style={{height: expand && location.pathname !=="/home" && location.pathname !=="/" ? "500px" : location.pathname === "/home" ? null : location.pathname === "/" ? null : null }}
        >
        <div class="cover" id="test"> </div>  
         
        <Button 
              className={expand ? "animateButton" : "animateButton2" }
              id="clicker"
              shape='circle'
              onClick={() => handleExpand()}
              style={{
                zIndex: 10,
                border: "none",
                backgroundColor: "whitesmoke",
                color: "#1d1f8c",
                width: "40px",
                height:"40px",
                position: "absolute",
                left: "50%",
                right: "50%",
                transform: 'translateX(-50%)', 
                top: 80,
                display: location.pathname === "/mpe-ael-calc" || location.pathname === "/fiber-optic" || location.pathname === "/diffuse-reflective" || location.pathname === "/lookup-table" ? null : 'none' ,
                }} 
            >
            <span style={{ pointerEvents: 'none' }}>
              {expand ? <UpOutlined  style={{ pointerEvents: 'none' }}/> : <DownOutlined style={{ pointerEvents: 'none' }}/>}
            </span>
        </Button>
          <Navbar.Brand className='brand'>
            <NavLink to="/home">
              <img src={RTLLSS} width={200} alt='logo-RealtimeLSS' loading="lazy"></img>
            </NavLink>
          </Navbar.Brand>
          {/* <div
            class="vr d-none d-xl-block "
            style={{ marginRight: "8px" }}
          ></div> */}
          
          <div id="responsive-navbar-nav"  className={expand ? "animateImage" : "animateImage2"}>
            <Nav id="responsive-navbar-nav" style={{ display: location.pathname === "/home" || location.pathname === "/"  ? 'none' : "flex"}}>
                <>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to="/mpe-ael-calc"
                      className={({ isActive }) =>  (isActive ? "gradient-border-bottom active card2 activeCard" : "hover-underline-animation card2")}
                      >
                      {/* <CalculatorOutlined /> Point and Extended */}
                      <img src={PointnadExtendedP} height={300}></img>
                      <div class="intro2">
                            <h1>Point & Extended</h1>
                            <p>The point source has a laser emission from a single point and has a miniscule diameter 
                                compared with the viewing distance while an extended source is the opposite with
                              laser emitted from various points and a relatively large diameter.
                            </p>
                      </div>    
                    </NavLink>
                    <NavLink to="/mpe-ael-calc" className="link"> <h1>Point & Extended</h1></NavLink>
                  </Nav.Link>
                </div>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to="/fiber-optic"
                      className={({ isActive }) => (isActive ? "gradient-border-bottom active card2 activeCard" : "hover-underline-animation card2")}
                      >
                      <img src={FiberOpticP} height={300}></img>
                      <div class="intro2">
                          <h1>Fiber Optic</h1>
                            <p>Utilises flexible quartz or glass fibres that use total internal reflection to pass light through
                                thousands of glancing reflections. Optical sources typically used are laser diodes,
                                LEDs and VCSEL (Vertical Cavity Surface Emitting Laser).
                            </p>
                      </div>    
                      {/* <AimOutlined /> Fiber Optic */}
                    </NavLink>
                    <NavLink to="/fiber-optic" className="link"> <h1>Fiber Optic</h1></NavLink>
                  </Nav.Link>
                </div>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to="/diffuse-reflective"
                      className={({ isActive }) => (isActive ? "gradient-border-bottom active card2 activeCard" : "hover-underline-animation card2")}
                      >
                      <img src={DiffuseReflection2} height={300}></img>
                      {/* <SelectOutlined /> Diffuse Reflection */}
                      <div class="intro2">
                          <h1>Diffuse Reflection</h1>
                          <p>This happens when different parts of a beam strike a surface and reflects over a wide range of angles.
                                The reflection typically strikes a rough or irregular surface.
                          </p>
                      </div>   
                    </NavLink>
                    <NavLink to="/diffuse-reflective" className="link"> <h1>Diffuse Reflection</h1></NavLink>
                  </Nav.Link>
                </div>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to="/lookup-table"
                      className={({ isActive }) =>  (isActive ? "gradient-border-bottom active  card2 activeCard" : "hover-underline-animation card2")}
                      >
                      <img src={LookupTableP} height={300}></img>
                      {/* <SearchOutlined /> Lookup Table */}
                      <div class="intro2">
                          <h1>Lookup Table</h1>
                          <p>Find out the accessible emission limit for all laser class in relation to the laser wavelength you want to calculate.
                                Evaluate the laser properties to determine safety values for eye and skin.
                          </p>
                      </div>      
                    </NavLink>
                    <NavLink to="/lookup-table" className="link"> <h1>Lookup Table</h1></NavLink>
                  </Nav.Link>
                </div>
              </>
            </Nav> 
          </div>

      
        </Navbar>
        </>
     
     
      {/* Edit Profile Modal      */}
      {/* <EditProfile
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        myUser={myUser}
        setMyUser={setMyUser}
        isLocal={isLocal}
      /> */}
      {/* {myLaserProfile !== null ? ( */}
      {/* <> */}
      {/* Laser Profile Modal */}
        {/* <LaserProfileModal
          myUser={myUser}
          laserProfileVisibility={laserProfileVisibility}
          setLaserProfileVisibility={setLaserProfileVisibility}
          deleteIsLoading={deleteIsLoading}
          handleDeleteProfile={handleDeleteProfile}
          myLaserProfile={myLaserProfile}
      />
      </>
     ): null} */}
    </>
 
  ); 
}


export default  Nav_bar 