import React, { createContext, useState } from 'react'

const EnvironmentContext = createContext()

const EnvironmentProvider = ({ isLocal, children }) => {
    const [email, setEmail] = useState();
    const [otp, setOTP] = useState();
    return (
        <EnvironmentContext.Provider
            value={{ isLocal, otp, setOTP, setEmail, email }}
        >
            {children}
        </EnvironmentContext.Provider>
    )
}

export { EnvironmentContext, EnvironmentProvider }