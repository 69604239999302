import { useEffect, useState } from 'react';
import './App.css';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import { AccountBookFilled, CalculatorOutlined, EditOutlined, HomeOutlined, LogoutOutlined, SearchOutlined } from '@ant-design/icons'
import { Layout, theme, ConfigProvider } from 'antd';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Cookies from 'js-cookie';
import Loginv2 from './pages/Loginv2';
import Logout from './pages/Logout';
import MPE_AEL from './pages/Loginv2/Calculators/MPE_AEL';
import Register from './pages/Register'
import Nav_bar from './components/Navbar';
import LookupTable from './pages/Loginv2/Calculators/LookupTable'
import FiberOptic from "./pages/Loginv2/Calculators/FiberOptic";
import DiffuseReflectiveCalculator from './pages/Loginv2/Calculators/DiffuseReflectiveCalculator';
import Report from './pages/Report';
import RegistrationSuccess from './pages/RegistrationSuccess';
import ChangeProfile from './pages/ChangeProfile';
import FetchLaserProfile from './components/MyLaserProfileFetch';
import PurchaseSuccess from './components/PurchaseSuccess';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import { Contacts } from './components/Footer/Contact';
import { TermsAndConditions } from './components/Footer/TermsAndCondition'
import { PrivacyPolicy } from './components/Footer/PrivacyPolicy';
import { About } from './components/Footer/About';
import { EnvironmentProvider } from './routes/EnvironmentContext';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import VerifyCode from './components/ForgotPassword/VerifyCode';
import ForgotChangePassword from './components/ForgotPassword/ChangePassword';
import ForgotPasswordSuccess from './components/ForgotPassword/ForgotPasswordSuccess';
const { Header, Content, Footer } = Layout;



const getItem = (key, label, icon, children, type) => {
  return {
    key,
    label,
    icon,
    children,
    type
  }
}

const rtllSettings = { expires: 365 }

function App() {
  // change to false if you are planning to upload to render.
  // true if it's just for local use.
  if (!Cookies.get('RTLSS_SETTINGS')) Cookies.set(`RTLSS_SETTINGS`, JSON.stringify({}), rtllSettings)
  const [isLocal] = useState(false)
  const { token: { colorBgContainer } } = theme.useToken()
  const [myUser, setMyUser] = useState(null)
  // const [myLaserProfile, setMyLaserProfile] = useState(null)
  const [myCommits, setMyCommits] = useState(null)
  const [gitIsFetching, setGitIsFetching] = useState(false)
  const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(myUser, isLocal)
  const [userLocation, setUserLocation] = useState(null)
  const [disableNav, setDisableNav] = useState(false)
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("isdisabled?",disableNav)
    if (Cookies.get('RTLSS_user') !== undefined) {
      let rtlssUser = Cookies.get(`RTLSS_user`)
      if (rtlssUser.includes('j:')) {
        rtlssUser = rtlssUser.split('j:')[1]
        console.log(`RTLSS UER: `, rtlssUser)
        setMyUser(JSON.parse(rtlssUser))
      } else {
    
        console.log(`parsing............................`, Cookies.get('RTLSS_user'))
        setMyUser(JSON.parse(Cookies.get('RTLSS_user')))
      }
    } else {
      console.log(`RTLSS UER: `, Cookies.get("RTLSS_user"))
      setMyUser(null)

    }

  }, [pathname])


  const navLinkItemV2 = [
    getItem("lasermet", "Lasermet Ltd.,", null, [
      getItem("Calculators", "Calculators", <HomeOutlined />, [
        getItem(1, <NavLink to='/mpe-ael-calc'>MPE & AEL</NavLink>, <CalculatorOutlined />),
        getItem(2, <NavLink to='/lookup-table'>Lookup Table</NavLink>, <SearchOutlined />)
      ], 'group'),
      getItem(3, 'Account Settings', <AccountBookFilled />, [
        getItem('profile', <NavLink to='/edit-profile'>Edit Profile</NavLink>, <EditOutlined />),
        getItem('logout', <NavLink to='/logout'>Logout</NavLink>, <LogoutOutlined />),
        // getItem('protected', <NavLink to='/auth-endpoint'>Protected</NavLink>)
      ], 'group')
    ], 'group')
  ]

  const handleLogout = () => {
    setMyUser(null)
  }

  return (
    <EnvironmentProvider isLocal={isLocal}>
      {
        laserProfileIsFetching ? (
          null //spinner
        ) : (

          <>
            <Header style={{ position: "relative", display: disableNav === true ? "none" : null}}>
                <Nav_bar isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
              </Header>
          

            <ConfigProvider
              theme={{
                token: {
                  colorText: "#1d1f8c",
                  colorPrimary: "#1d1f8c"
                },
              }}
            >
              <Content style={{ height: '100vh', position: 'static' }}>
                {/* <div
                  class="setback"
                  style={{
                    paddingTop: 0,
                    minHeight: '100vh',
                    marginTop: "-18px"
                  }}> */}

                <Routes>
                  <Route path='/'
                    element={
                      <ProtectedRoutes>
                        <Home isLocal={isLocal} myCommits={myCommits} gitIsFetching={gitIsFetching} myUser={myUser} setMyUser={setMyUser} myLaserProfile={myLaserProfile} />
                      </ProtectedRoutes>
                    } />
                  <Route path='/home'
                    element={
                      <ProtectedRoutes>
                        <Home isLocal={isLocal} myCommits={myCommits} myUser={myUser} setMyUser={setMyUser} myLaserProfile={myLaserProfile} />
                      </ProtectedRoutes>
                    } />

                  <Route path='/login' element={<Loginv2 setMyUser={setMyUser} myUser={myUser} isLocal={isLocal} disableNav={disableNav} setDisableNav={setDisableNav} />} />

                  <Route path='/mpe-ael-calc' element={
                    <ProtectedRoutes>
                      <MPE_AEL isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  } />

                  <Route path="/fiber-optic" element={<ProtectedRoutes>
                    <FiberOptic isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                  </ProtectedRoutes>
                  } />

                  <Route path='/diffuse-reflective' element={
                    <ProtectedRoutes>
                      <DiffuseReflectiveCalculator isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  } />

                  <Route path='/lookup-table' element={
                    <ProtectedRoutes>
                      <LookupTable isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  } />

                  <Route path='/edit-profile' element={
                    <ProtectedRoutes>
                      Edit profile page goes here.
                    </ProtectedRoutes>
                  } />
                  <Route path='/report' element={
                    <ProtectedRoutes>
                      <Report myUser={myUser} isLocal={isLocal} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  }>
                  </Route>

                  <Route path='/register' element={
                    <Register isLocal={isLocal} />
                  } />

                  <Route path='/confirm' element={
                    <RegistrationSuccess isLocal={isLocal} />
                  } />

                  <Route path='/secure-change' element={
                    <ProtectedRoutes>
                      <ChangeProfile isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  } />

                  <Route path='/purchase-success' element={
                    <ProtectedRoutes>
                      <PurchaseSuccess isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  } />

                  <Route path='/subscription-success' element={
                    <ProtectedRoutes>
                      <SubscriptionSuccess isLocal={isLocal} myUser={myUser} setMyUser={setMyUser} />
                    </ProtectedRoutes>
                  } />

                  <Route path='/contacts' element={
                    <ProtectedRoutes>
                      <Contacts />
                    </ProtectedRoutes>
                  }>
                  </Route>
                  <Route path='/terms-and-conditions' element={
                      <TermsAndConditions />
                  }>
                  </Route>
                  <Route path='/privacy-policy' element={
                      <PrivacyPolicy />
                  }>
                  </Route>
                  <Route path='/about' element={
                    <ProtectedRoutes>
                      <About />
                    </ProtectedRoutes>
                  }>
                  </Route>

                  <Route path='/forgotPassword' element={
                    <ForgotPassword />
                  }>
                  </Route>
                  <Route path='/verifyCode' element={
                    <VerifyCode />
                  }>
                  </Route>

                  <Route path='/forgot-change-password' element={
                    <ForgotChangePassword />
                  }>
                  </Route>

                  <Route path='/forgot-password-success' element={
                    <ForgotPasswordSuccess/>
                  }>
                  </Route>


                  <Route path='/logout' element={<Logout handleLogout={handleLogout} setDisableNav={setDisableNav}/>} />

                </Routes>
                {/* </div> */}
                </Content>
                </ConfigProvider>

          </>
        )
      }
    </EnvironmentProvider>

  );

}

export default App;
